import * as React from 'react';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import { PAGES } from '../config/constants';
import { withQuery } from '../utils/withQuery';
import Layout from '../components/layout';
import Heading from '../components/heading';

const Container = styled.article`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 4.5rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid;
  
  &:first-of-type {
    margin-top: -3rem;
  }

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  @media screen and (min-width: 481px) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  
  @media screen and (min-width: 481px) {
    margin-left: 1.25rem;
  }
  
  @media screen and (min-width: 769px) {
    margin-left: 2.25rem;
  }
`;

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: .25rem;
`;

const Published = styled.p`
  margin-bottom: 2rem;
  
  a {
    color: inherit;
  }
`;

const Summary = styled.div`
  p {
    text-align: justify;
    font-size: 1.125rem;
    line-height: 1.675rem;
    margin-bottom: 1.375rem;
  }
 
  blockquote {
    position: relative;
    //font-style: italic;
    line-height: 1;
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;

    p {
      margin-bottom: .125rem;
    }
  }

  > *:last-child {
    margin-bottom: 0;
    
    > *:last-child {
      margin-bottom: 0;
    }
  }
`;

const CoverWrapper = styled.div`
  //border: 2px solid #212121;
  margin-top: .25rem;
  margin-bottom: 2rem;
  
  a {
    display: block;
  }
  
  @media screen and (min-width: 481px) {
    margin-bottom: 0;
  }
`;

const Cover = styled(Img)`
  //max-height: 250px;
  //height: auto;
  //width: 165px;
  border: 2px solid #212121;
`;

const SelectedPoems = styled.div`
  margin-top: 3rem;
  
  li {
    margin-bottom: .375rem;
  }
  
  p {
    font-size: 1.125rem;
    line-height: 1.4;
    margin-bottom: 0;
    
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Collection = ({
  url,
  title,
  year,
  publisher,
  cover,
  summary,
  slug,
  poems,
}) => {
  const Url = ({ children, ...rest }) => url
    ? <a href={url} target="_blank" rel="noopener noreferrer" {...rest}>{children}</a>
    : children;

  return (
    <Container id={slug} key={slug}>
      <CoverWrapper>
        <Url>
          <Cover fixed={cover.fixed} alt={title}/>
        </Url>
      </CoverWrapper>
      <Content>
        <Title>{title}</Title>
        <Published>
          {publisher}, {year}
          {url ? (
            <div css={{  marginTop: '1.25rem' }}>
              <Url>
                Buy {title} from {publisher}
              </Url>
            </div>
          ) : ''}
        </Published>
        <Summary>
          {summary}
        </Summary>
        {poems ? (
          <SelectedPoems>
            <h3 css={{ fontStyle: 'italic', marginBottom: '1.25rem' }}>Selected poems from {title}</h3>
            <ul>
              {poems.map(({ title, path }) => (
                <li key={path}>
                  <p>
                    <Link to={path}>{title}</Link>
                  </p>
                </li>
              ))}
            </ul>
          </SelectedPoems>
        ) : ''}
      </Content>
    </Container>
  );
};

const Collections = ({ collections = [] }) => (
  <Layout
    pageTitle="Collections"
    pageDescription="Information about Linda Black's poetry collections published by Shearsman Books and Hearing Eye"
    breadcrumbTrail={[PAGES.COLLECTIONS]}
  >
    <Heading>Collections</Heading>
    {collections.map(Collection)}
  </Layout>
);

export const query = graphql`
  query Collections {
    allContentfulCollection(sort: {fields: publishDate, order: DESC}) {
      nodes {
        id
        title
        publisher
        publishDate
        url
        summary {
          raw
        }
        fields {
          slug
        }
        cover {
          fluid {
            src
          }
          fixed(width: 165, height: 250) {
            ...GatsbyContentfulFixed
          }
          #          thumb: fluid(maxWidth: 328, maxHeight: 328) {
          #            ...GatsbyContentfulFluid
          #          }
          #          full: fluid(maxWidth: 2048) {
          #            ...GatsbyContentfulFluid
          #          }
        }
      }
    }
    allContentfulPoem(sort: {fields: title, order: ASC}) {
      group(field: from___id) {
        fieldValue
        edges {
          node {
            id
            title
            fields {
              path
            }
          }
        }
      }
    }
  }
`;

const mapDataToProps = ({ allContentfulCollection: { nodes }, allContentfulPoem: { group } }) => {
  let poems = {};

  group.forEach(({ fieldValue: collectionId, edges }) => {
    poems[collectionId] = edges.map(({ node: { title, fields: { path } } }) => ({
      title,
      path,
    }));
  });

  const collections = nodes.map(({ id, title, url, publishDate, publisher, cover, summary, fields: { slug } }) => {
    const parsedSummary = summary?.raw && JSON.parse(summary.raw);

    // Contentful sometimes adds an extra, empty par on the end, e.g. if you try to end with a blockquote.
    const { content } = parsedSummary || {};
    if (content) {
      const last = content[content.length - 1];

      if (last.content.length === 1 && last.content[0].value === '') {
        content.pop();
      }
    }

    return {
      slug,
      title,
      url,
      year: (new Date(publishDate)).getFullYear(),
      publisher,
      cover,
      summary: parsedSummary && documentToReactComponents(parsedSummary),
      poems: poems[id],
    };
  });

  return { collections };
};

export default withQuery(mapDataToProps)(Collections);
